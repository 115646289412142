import React from "react"
import ReactDOM from 'react-dom'
import styled, { keyframes } from "styled-components"

/* props
    position = fixed
    opacity = 0
    scaleWidthPercent = 80
    animationDelay = 0
    animationDurationSize = 2
    animationDurationPosition = 2

    width = fit-content
    height = fit-content

    after = show same content
 */


export default function ContainerScaleEffect(props) {
  let childElement = React.cloneElement(props.children, { width: "100%", height: '100%' })

  let element = null
  let elementDef = {style: {}}

  const animationDelay = props.animationDelay ? props.animationDelay : [0, 0.5]
  const animationDurationSize = props.animationDurationSize ? props.animationDurationSize : 2
  const animationDurationPosition = props.animationDurationPosition ? props.animationDurationPosition : 2
  
  const handleImmediateClose = () => {
    if(document.getElementById("ScaleEffect")) {
      document.getElementById("ScaleEffect").outerHTML = "";
    }
    if(element) {
      element.style.opacity = elementDef.style.opacity
    }
  }

  const closeEvent = () => {
    const bounding = element.getBoundingClientRect()

    const scaleWidthPercent = props.scaleWidthPercent ? props.scaleWidthPercent : 80
    const imageScalePercent = (window.innerWidth * scaleWidthPercent / 100) * (100 / bounding.width) / 100

    const ScaleEffectCloseSize = keyframes`
      to {
        height: ${bounding.height}px;
        width: ${bounding.width}px;
      }`

    const ScaleEffectDefPosition = keyframes`
      to {
        transform: initial;
        top: ${bounding.y}px;
        left: ${bounding.x}px;
      }`

    const AnimatedContainer = styled.div`
      animation-delay: ${animationDelay[0]}s, ${animationDelay[1]}s;
      animation-duration: ${animationDurationPosition}s, ${animationDurationSize}s;
      animation-fill-mode: forwards;
      animation-name: ${ScaleEffectCloseSize}, ${ScaleEffectDefPosition}`

    const scale = <div style={{position: "absolute", width: "100%", height: "100%"}}>
      <AnimatedContainer
        style={{"backgroundColor": "black", "position": props.position ? props.position : "fixed", "left": "50%", "top": "50%", "transform": "translate(-50%, -50%)", "width": `${scaleWidthPercent}%`, "height": `${bounding.height * imageScalePercent}px`}}
      >
        { childElement }
      </AnimatedContainer>
    </div>

    ReactDOM.render(scale, document.getElementById('ScaleEffect'));
 
    setTimeout(() => {
      handleImmediateClose()
    }, (animationDelay[0] + animationDelay[1] + animationDurationSize + animationDurationPosition) * 1000)
  }

  const handleScale = (target) => {
    element = target
    const bounding = element.getBoundingClientRect()

    const scaleWidthPercent = props.scaleWidthPercent ? props.scaleWidthPercent : 80
    const imageScalePercent = (window.innerWidth * scaleWidthPercent / 100) * (100 / bounding.width) / 100

    if(props.opacity !== undefined) {
      elementDef.style.opacity = element.style.opacity
      element.style.opacity = props.opacity
    }

    //style
    const ScaleEffectCenter = keyframes`
      to {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }`

    const ScaleEffectSize = keyframes`
      to {
        height: ${props.height ? props.height : bounding.height * imageScalePercent + 'px'};
        width: ${scaleWidthPercent}%;
      }`

    const AnimatedContainer = styled.div`
      animation-delay: ${animationDelay[0]}s, ${animationDelay[1]}s;
      animation-duration: ${animationDurationPosition}s, ${animationDurationSize}s;
      animation-fill-mode: forwards;
      animation-name: ${ScaleEffectCenter}, ${ScaleEffectSize};`

    const scale = <div onClick={() => closeEvent()} style={{position: "absolute", width: "100%", height: "100%"}}>
      <AnimatedContainer style={{"backgroundColor": "black", "position": props.position ? props.position : "fixed", "top": `${bounding.y}px`, "left": `${bounding.x}px`, "width": bounding.width, "height": bounding.height}}>
        {childElement}
      </AnimatedContainer>
    </div>
    
    let div = document.createElement('div');
    div.setAttribute("id", "ScaleEffect")
    document.getElementById('root').appendChild(div)
    ReactDOM.render(scale, document.getElementById('ScaleEffect'), () => {
      // to do after animation can change content
    })
  }

  window.addEventListener('scroll', handleImmediateClose);

  return (
    <div style={{width: props.width ? props.width : 'fit-content', height: props.height ? props.height : 'fit-content'}} onClick={(e) => handleScale(e.target)}>
      { childElement }
    </div>
  );
}