import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import "./style.css"


export default function Footer() {
    return (
        <div className={"container-fluid m-0 p-0 text-center footerMain"}>
            {/* Created by <a href="#ss">DrinkingCompanion LLC</a> */}
        </div>
    )
}