import React, {useState} from 'react'
import { Link, Box } from '@material-ui/core/'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import 'bootstrap/dist/css/bootstrap.css';
import './style.css'
import { animateScroll } from 'react-scroll'


export default function GoTop() {
    const [show, setShow] = useState(false)

    window.onscroll = function(ev) {
        if(window.scrollY > 100) {
            setShow(true)
        }else{
            setShow(false)
        }
        console.log(show)
    };

    let scroll = animateScroll;

    return (
        <Box className={"go-top"} border={1} style={{'borderColor': '#ff1edc', 'display': show? 'block':'none'}} borderRadius={8}>
            {/*<SmoothScrollLink href={"#header"} activeClass="active" to="header" spy={true} smooth={true} duration={1000} >*/}
            <span onClick={() => scroll.scrollToTop({
                delay: 0,
                duration: 200,
            })}>
                <ArrowDropUpIcon className={"go-top-icon"} />
            </span>
            {/*</SmoothScrollLink>*/}
        </Box>
    )
}