import React from "react"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import ContainerScaleEffect from "../ContainerScaleEffect"
import "./style.css"
import jpg1 from '../media/galery/1.jpg'
import jpg2 from '../media/galery/2.jpg'
import jpg3 from '../media/galery/3.jpg'
import jpg4 from '../media/galery/4.jpg'
import jpg5 from '../media/galery/5.jpg'
import jpg6 from '../media/galery/6.jpg'
import jpg7 from '../media/galery/7.jpg'
import jpg8 from '../media/galery/8.jpg'

export default function Gallery(props) {

    const galleryList = [
        {img: jpg1, title: ""},
        {img: jpg2, title: ""},
        {img: jpg3, title: ""},
        {img: jpg4, title: ""},
        {img: jpg5, title: ""},
        {img: jpg6, title: ""},
        {img: jpg7, title: ""},
        {img: jpg8, title: ""},
    ]

    return (
        <GridList cellHeight={props.pageWidth / 100 * (props.pageWidth <= 720 ? 48 : 24)} cols={props.pageWidth <= 720 ? 2 : 4} style={{"margin": "0"}}>
            {galleryList.map((tile) => (
                <GridListTile key={tile.img}>
                    <ContainerScaleEffect animationDelay={[0, 0.3]} animationDurationSize={0.3} animationDurationPosition={0.3} scaleWidthPercent={props.pageWidth <= 720 ? 90 : 50}>
                        <img src={tile.img} alt={tile.title} />
                    </ContainerScaleEffect>
                </GridListTile>
            ))}
        </GridList>
    )
}