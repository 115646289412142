import React, { useState, useEffect } from "react"
import Header from "../../Components/Header"
import Player from "../../Components/Player"
import Gallery from "../../Components/Gallery"
import Contact from "../../Components/Contact"
import Footer from "../../Components/Footer"
import GoTop from "../../Components/GoTop"
import "./style.css"
import { Ripple } from "react-preloaders"


export default function Home() {
  const [pageWidth, updatePageWidth] = useState(null)

  useEffect(() => {
    updatePageWidth(window.innerWidth)
    window.addEventListener("resize", () => {
        updatePageWidth(window.innerWidth)
    });
  })

  return (
    <div className={"homeMain"} style={{"overflowX": "auto"}}>
      <Header pageWidth={pageWidth} />
      <Player pageWidth={pageWidth} pageHeight={window.innerHeight} />
      <Gallery pageWidth={pageWidth} />
      <Contact pageWidth={pageWidth} />
      <Footer pageWidth={pageWidth}  />
      <GoTop />
      <Ripple animation={'slide-down'} background={'#2a0410'} color={'#ff1edc'} />
    </div>
  );
}