import React from "react"
import ReactDOM from "react-dom"
import Home from './Pages/Home'
import Menu from './Pages/Menu'
// import "../../public/translation/i18n"
import 'smoothscroll-anchor-polyfill';
import { BrowserRouter, Route } from "react-router-dom"

ReactDOM.render(
  <BrowserRouter>
    <Route exact path="/">
        <Home />
    </Route>
    <Route exact path="/menu">
        <Menu />
    </Route>
  </BrowserRouter>,
  document.getElementById("root")
);
