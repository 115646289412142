import React, { useEffect, useState } from "react"
import ReactPlayer from 'react-player'
import famousHookah from '../media/videos/famoushookah.mp4'
import famousHookahMobile from '../media/videos/famoushookahmobile.mp4'
import Typical from 'react-typical'
import "./style.css"
import 'bootstrap/dist/css/bootstrap.css'

const steps = [
  'Welcome to Famous Cafe & Lounge Tsaghkadzor👋🏻', 1500,
  'Open every day 12:00-02:00', 1500,
  '📲098 633 336 ☎️0223-75557', 1500,
  'When you made the right choice⚜️', 3000,
];

let typical = <Typical wrapper="span" steps={steps} loop={1000} className={'caca element-on-video Typical-text-style'} />

export default function Player(props) {
  return (
    <div style={{position: 'relative'}}>
      <ReactPlayer 
        url={props.pageWidth > props.pageHeight? famousHookah: famousHookahMobile}
        width="100%" 
        height="auto" 
        playing={true} 
        loop={true} 
        controls={false}
        volume={0}
        muted={true}
        playsinline={true}
        style={{"opacity": "0.5"}}
      />
      { typical}
    </div>
  );
}
