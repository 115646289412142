import React from "react"
import { AppBar, Toolbar, Link, Box, Menu } from '@material-ui/core/'
import LanguageMenu from "../LanguageMenu/"
import Image from 'react-bootstrap/Image'
import 'bootstrap/dist/css/bootstrap.css'
import Logo from '../media/logo.png'
import './style.css'
import { useTranslation } from 'react-i18next'
import MobileMenu from '../MobileMenu'
import HomeIcon from '@material-ui/icons/Home'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'

export default function Header(props) {

  const { t, i18n } = useTranslation()

  return (
    <AppBar id={"header"} position="absolute" className={"text-style"} style={{ background: 'transparent', boxShadow: 'none'}}>
        <Toolbar>
            <Box style={{flexGrow: 1}} m={1}>
              <a href={"/"}><Image src={Logo} height="100" style={{'opacity': '0.6'}} roundedCircle /></a>
            </Box>
            {
            // props.pageWidth > 720 ? <Box className={"mr-2"}>
            //   <Link href="/" className="top-link-style menuElement"><HomeIcon className={"menuIcon"} /> {t('Home')}</Link>
            //   <Link href="/menu" className="top-link-style menuElement"><RestaurantMenuIcon className={"menuIcon"} /> {t('Menu')}</Link>
            //   <Link href="/#contact" className="top-link-style menuElement"><ContactPhoneIcon className={"menuIcon"} /> {t('Contact')}</Link>
            //   {/*<LanguageMenu />*/}
            // </Box> :
            // <MobileMenu />
            }
        </Toolbar>
    </AppBar>
  );
}
